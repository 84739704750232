




















































import { RouterNames } from "@/router/routernames";
import { enumeracion } from "@/shared/dtos/enumeracion";
import { incidenciaAllDto } from "@/shared/dtos/incidencias/incidenciaAllDto";
import { inventarioIncidenciasDto } from "@/shared/dtos/incidencias/inventarioincidenciasDto";
import { MessageService } from "@/shared/services/message-service";
import enumeracionModule from "@/store/modules/enumeracion-module";
import incidenciaModule from "@/store/modules/incidencia-module";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class incidenciasListaMovil extends Vue {
  @Prop({ default: -1 }) id_inventario!: number;
  public enumeracines_estados: enumeracion[] = [];
  public datos_a_ver: boolean = false;

  created() {
    this.refres();
    enumeracionModule.getenumeraciones_fromtipo(4).then((x: enumeracion[]) => {
      this.enumeracines_estados = x;
    });
  }
  refres() {
    incidenciaModule.getincidencias_movil_id(this.id_inventario);
  }

  public get incidencias() {
    let datas: inventarioIncidenciasDto[] = [];
    for (let i = 0; i < incidenciaModule.incidencias_inventario.length; i++) {
      const element = incidenciaModule.incidencias_inventario[i];
      let elementos: incidenciaAllDto[] = [];

      if (this.datos_a_ver) {
        elementos = element.incidencias.filter(
          (k) =>
            k.estado.estate_closed === this.datos_a_ver ||
            k.estado.estate_supervised === true
        );
      } else {
        elementos = element.incidencias.filter(
          (k) =>
            k.estado.estate_closed === this.datos_a_ver &&
            k.estado.estate_supervised === false
        );
      }
      if (elementos.length > 0) {
        element.incidencias = elementos;
        datas.push(element);
      }
    }
    if (this.id_inventario !== -1) {
      datas = datas.filter((x) => x.inventario.id);
    }
    return datas;
  }
  public get estados() {
    return this.enumeracines_estados;
  }
  public complete(incidencia: incidenciaAllDto) {
    MessageService.confirm(
      "¿Seguro que quiere completar la incidencia " + incidencia.nombre + "?",
      "Completar",
      (x: boolean) => {
        if (x) {
          let estado_supervisar = this.estados.find(
            (k) => k.estate_supervised == true
          );
          if (estado_supervisar !== undefined) {
            incidencia.estado = estado_supervisar;
          } else {
            incidencia.estado = this.estados.find(
              (k) => k.estate_closed == true
            )!;
          }

          incidencia.id_estado = incidencia.estado.id;
          incidenciaModule
            .modificarincidencia(incidencia)
            .then((x) => this.refres());
        }
      }
    );
  }
  public open(inciencia: incidenciaAllDto) {
    this.$router.push({
      name: RouterNames.incidencias_movil,
      params: { id: inciencia.id },
    });
  }
}
