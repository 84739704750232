import { BaseDto } from "@/shared/dtos/base-dto";

export class enumeracion extends BaseDto {
  public nombre!: string;
  public id_tipo_enumeracion!: number;
  public orden!: number;
  public color!: string;
  public horas_resolucion!: number;
  public estate_closed!: boolean;
  public estate_supervised!: boolean;
}
